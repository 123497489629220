/**
 * @typedef FilterType
 * @property {string} name - The name of the status option.
 * @property {string} label - The label for the status option.
 * @property {number} id - The unique identifier for the status option.
 * @property {number} value - The value associated with the status option.
 *
 * Default status options for a process or workflow.
 * @type {FilterType[]}
 *
 */
export const DEFAULT_FILTER_ASSIGNMENT_STATUS_OPTION = [
  { name: "Prospecting", value: 0, id: 0, label: "Prospecting" },
  { name: "In progress", value: 1, id: 1, label: "In progress" },
  { name: "Closed", value: 2, id: 2, label: "Closed" },
  { name: "Lost", value: 3, id: 3, label: "Lost" },
  { name: "Interrupted", value: 4, id: 4, label: "Interrupted" },
];
