/** * @module string */

import { startCase } from "lodash";

/**
 * Converts a string to snake_case format.
 *
 * @param {string} s - The input string to convert.
 * @returns {string} The input string converted to snake_case format.
 *
 * @example
 * const inputString = "Convert This String";
 * const snakeCaseString = toSnakeCase(inputString);
 * console.log(snakeCaseString); // Output: "convert_this_string"
 */
export function toSnakeCase(s) {
  return `${s ?? ""}`?.trim().toLowerCase().replace(" ", "_");
}

/**
 * Converts a string to a searchable format by converting it to lowercase and trimming whitespace.
 *
 * @param {string} key - The input string to convert.
 * @returns {string} The input string converted to a searchable format.
 *
 * @example
 * const searchString = "  Searchable String  ";
 * const searchableString = toSearch(searchString);
 * console.log(searchableString); // Output: "searchable string"
 */
export function toSearch(key) {
  return `${key ?? ""}`.toLowerCase().trim();
}

/**
 * Converts a content string into a slug format suitable for URLs.
 *
 * @param {string} content - The content string to convert into a slug.
 * @returns {string} The content string converted into a slug format.
 *
 * @example
 * const title = "This is a Sample Title";
 * const slug = slugify(title);
 * console.log(slug); // Output: "this-is-a-sample-title"
 */
export function slugify(content) {
  return content.split(" ").join("-").toLowerCase().trim();
}

/**
 * Returns the provided value as a string, or an empty string if the value is falsy.
 *
 * @param {*} value - The value to convert to a string or return as an empty string if falsy.
 * @returns {string} The value converted to a string, or an empty string if falsy.
 *
 * @example
 * const str = getStringValueOrEmpty("Hello");
 * console.log(str); // Output: "Hello"
 *
 * const emptyStr = getStringValueOrEmpty(null);
 * console.log(emptyStr); // Output: ""
 *
 * const numStr = getStringValueOrEmpty(123);
 * console.log(numStr); // Output: "123"
 */
export function getStringValueOrEmpty(value) {
  return value || "";
}

/**
 *
 * @param {string} [htmlContent=""] - The HTML content to parse into plain text. Content that has html tags or not
 * @returns {string}  Some of content from api has html tag on it, like comment from admen or result description from admen
 *                     Need to parse those data before showing it on the UI
 *                     The plain text extracted from the HTML content.
 *
 * @example
 * const htmlContent = '<p>This is <strong>HTML</strong> content.</p>';
 * const plainText = parseHtmlToString(htmlContent);
 * console.log(plainText); // Output: "This is HTML content."
 */
export function parseHtmlToString(htmlContent = "") {
  // Create temporary element to hold the content
  let tempElement = document.createElement("div");
  tempElement.innerHTML = htmlContent;

  // Extract text
  const extractedText = tempElement.textContent || tempElement.innerText;

  // Clean up temporary element
  tempElement = null;

  return extractedText?.trim();
}

/**
 * Replaces special characters in a given string with an empty string.
 * The special characters include hyphens, spaces, asterisks, and parentheses.
 *
 * @param {string} value - The input string to process.
 * @returns {string} The processed string with special characters removed. Returns an empty string if the input is falsy.
 *
 * @example
 * const stringWithSpecialChars = "Hello *world* - (2023)";
 * const cleanedString = removeSpecialCharacters(stringWithSpecialChars);
 * console.log(cleanedString); // Output: "Hello world 2023"
 */
export function removeSpecialCharacters(value) {
  return value ? value.replace(/[- *()]+/, "") : "";
}

/**
 * Converts a camelCase or PascalCase string to snake_case by adding underscores before capital letters.
 *
 * @param {string} [text=""] - The input text to convert.
 * @returns {string} The converted snake_case string.
 */
export function camelToSnakeCase(text = "") {
  return text
    .replace(/([A-Z])/g, "_$1")
    .trim()
    .toLowerCase();
}

/**
 * Converts snake_case strings to CapitalCase (PascalCase).
 *
 * @param {string} value - The input string in snake_case format.
 * @returns {string} The converted CapitalCase (PascalCase) string.
 *
 * @example
 * const snakeCaseString = "convert_snake_case_to_capital_case";
 * const capitalCaseString = convertSnakeCaseToCapitalCase(snakeCaseString);
 * console.log(capitalCaseString); // Output: "Convert Snake Case To Capital Case"
 */
export function convertSnakeCaseToCapitalCase(value) {
  return value.split("_").reduce((acc, current) => acc + startCase(current));
}

/**
 * Combines the 'name' properties of elements into a single string separated by commas.
 *
 * @param {Array<Object>} elements - The array of objects containing 'name' properties to combine.
 * @returns {string} The combined string of names separated by commas.
 * @example
 * const elements = [
 *   { name: 'John' },
 *   { name: 'Doe' },
 *   { name: 'Jane' }
 * ];
 * const combinedNames = extractCombinedName(elements);
 * console.log(combinedNames); // Output: "John, Doe, Jane"
 */
export function extractCombinedName(elements = []) {
  return elements
    .reduce((accumulator, currentValue) => {
      accumulator.push(`${currentValue.name}`);
      return accumulator;
    }, [])
    .join(", ");
}

/**
 * Removes all whitespace characters from a string.
 * @param {string} input - The string from which whitespace characters will be removed.
 * @returns {string} - The string with all whitespace characters removed.
 */
export function removeWhitespace(input) {
  return input.replace(/\s/g, "");
}
